<template>
  <selection1
    @result="selection1_result('extra', ...arguments)"
    v-model="selected"
    :list="list"
    id="branchId"
    desc="branchName"
  ></selection1>
</template>
<script>
import Selection1 from "./Selection1.vue";
export default {
  props: ["msg"],
  data() {
    return {
      selected: {},
      list: [
        {
          branchId: "97d75842-55b5-4339-84ed-bbc8794f5cb2",
          branchName: "ลาดพร้าว",
          code: "1",
          address: "59/3 (ปากซอย)นาคนิวาส5 แขวง/เขตลาดพร้าว กทม 10230",
          workingTime: "11:00-20:00 น. หยุดวันจันทร์",
          phone: "083-109-7111, 097-210-1000",
          description: null,
          state: true,
          runningOrder: 1,
          createDateTime: "2019-06-27T01:51:07.918394",
          createBy: "bell",
          updateDateTime: "2019-08-07T17:49:13.545918",
          updateBy: "System"
        },
        {
          branchId: "28a68ad5-e814-4e33-baa3-ea68d295b6af",
          branchName: "เมืองทองธานี",
          code: "2",
          address:
            "50/803 ซอยหมู่บ้านเมืองทอง โครงการ6 ซอยบี 1 ต.บางพูด อ.ปากเกร็ด จ.นนทบุรี 11120",
          workingTime: "11:00-20:00 หยุดวันจันทร์",
          phone: "083-109-8111 , 097-210-1000",
          description: null,
          state: true,
          runningOrder: 1,
          createDateTime: "2019-06-27T02:17:55.44207",
          createBy: "bell",
          updateDateTime: "2019-08-08T11:20:18.334061",
          updateBy: "System"
        },
        {
          branchId: "5244e0fd-14aa-4526-ba1c-4ae86ca4f931",
          branchName: "ราชพฤกษ์",
          code: "3",
          address: "ราชพฤกษ์",
          workingTime: "11:00 - 20:00",
          phone: "098-483-4938",
          description: null,
          state: true,
          runningOrder: 0,
          createDateTime: "2019-08-18T16:39:59.819891",
          createBy: "bell",
          updateDateTime: "2019-08-18T16:39:59.819987",
          updateBy: "bell"
        }
      ],
      welcome: "This is your profile"
    };
  },
  methods: {
    selection1_result(extra, value) {
      // console.log("result => ", value, " ind >>> ", ind);
      console.log("result => ", value, " extra >>> ", extra);
    }
  },
  components: {
    Selection1
  }
};
</script>

<style >
</style>
