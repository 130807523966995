<template>
  <div>
    <vs-row vs-w="12">
      <ul class="demo-alignment">
        <li
          @click="bluelightSelect($event, index)"
          class="notChoose"
          v-for="(b, index) in list"
          :key="index"
        >{{b[desc]}}</li>
      </ul>
    </vs-row>
  </div>
</template>
<script>
import shapeFormat from "@/mixins/shapeFormat.js";
export default {
  props: {
    list: Array,
    id: String,
    desc: String
  },
  mixins: [shapeFormat],
  data() {
    return {
      selection: null

      // welcome: "This is your profile"
    };
  },
  mounted() {
    var grid = document.getElementsByClassName("notChoose");
    grid[0].className = "choose";
    this.selection = this.list[0];
    // console.log("this.selection >>> ", this.selection);
  },
  methods: {
    bluelightSelect(event, index) {
      var grid = document.getElementsByClassName("choose");

      for (var k = 0; k < grid.length; k++) {
        grid[k].className = "notChoose";
      }

      if (event.target.className === "choose") {
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.selection = this.list[index][this.id];
      }

      this.$emit("result", this.selection);
    }
  },
  components: {}
};
</script>

<style >
</style>
